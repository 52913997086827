/* You can add global styles to this file, and also import other style files */
/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/
/*@import "~material-design-icons/iconfont/material-icons.css";*/
@import "~highlight.js/styles/googlecode.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~codemirror/lib/codemirror";
@import "~codemirror/theme/material";

// header base skins
.kt-header-base-light {
  @import "sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "sass/global/layout/header/skins/base/dark.scss";
}

// header menu skins
.kt-header-menu-light {
  @import "sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "sass/global/layout/header/skins/menu/dark.scss";
}

// brand skins
.kt-brand-dark {
  @import "sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "sass/global/layout/brand/skins/light.scss";
}

// aside skins
.kt-aside-dark {
  @import "sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "sass/global/layout/aside/skins/light.scss";
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    /*color: rgba(0,0,0,.12);*/
    color: #D3D3D9;
}


.input-group-prepend, .input-group-append {
    display: -ms-flexbox;
    display: flex;
}

.mat-tab-body-content {
    overflow: hidden !important;
}

.mat-form-field-color .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0;
}


.mat-form-field-color input {
    padding: 0.5em;
}

//Codemirror in product widget
.product-widget-container .CodeMirror {
    height: 505px;
}

#product-edit-form .mat-tab-label {
    min-width: unset !important;
}
