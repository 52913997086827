//
// Badge
//

//In dropdown filter
.mat-list-text {
    .kt-badge--inline {
        width: fit-content;
        padding: 0 8px !important;
        border-radius: 5px;

        @each $name, $color in $kt-state-colors {
            &.kt-badge--#{$name} {
                color: kt-get($color, base);
                background: kt-get($color, inverse);
                font-weight: 600!important;
            }
        }
    }
}

.kt-badge {
    padding: 0;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    font-size: 0.8rem;
    // Font options
    &.kt-badge--bold {
        font-weight: 600;
    }

    &.kt-badge--bolder {
        font-weight: 600;
    }

    &.kt-badge--boldest {
        font-weight: 700;
    }
    // Sizing
    &.kt-badge--md {
        height: 24px;
        width: 24px;
        font-size: 0.9rem;
    }

    &.kt-badge--lg {
        height: 35px;
        width: 35px;
        font-size: 1rem;
    }

    &.kt-badge--xl {
        height: 50px;
        width: 50px;
        font-size: 1.1rem;
        font-weight: 500;
    }
    // Pics
    &.kt-badge__pics {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .kt-badge__pic {
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            overflow: hidden;
            margin-left: -0.7rem;
            border: 1px solid #ffffff;
            background-color: #f0f3ff;
            color: #a1a8c3;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-weight: 500;
            position: relative;

            img {
                width: 100%;
            }

            &.kt-badge__pic--last {
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }
    // State colors
    @each $name, $color in $kt-state-colors {
        &.kt-badge--#{$name} {
            color: kt-get($color, base);
            background: kt-get($color, inverse);
            font-weight: 600;
        }

        &.kt-badge--outline.kt-badge--#{$name} {
            background: transparent;
            color: kt-get($color, base);
            border: 1px solid kt-get($color, base);

            &.kt-badge--outline-2x {
                border: 2px solid kt-get($color, base);
            }
        }
    }
    // State colors
    @each $name, $color in $kt-state-colors {
        &.kt-badge--unified-#{$name} {
            color: kt-get($color, inverse);
            background: kt-get($color, base);
            /*background: rgba(kt-get($color, base), 0.1);*/
        }
    }
    // Inline mode
    &.kt-badge--inline {
        height: auto;
        width: auto;
        padding: 0.15rem 0.75rem;
        // Sizing
        &.kt-badge--md {
            padding: 0.8rem 0.6rem;
        }

        &.kt-badge--lg {
            padding: 0.9rem 0.7rem;
        }

        &.kt-badge--xl {
            padding: 1rem 0.8rem;
        }

        @include kt-rounded {
            border-radius: 2px;
        }
    }    
    // Pill mode
    &.kt-badge--pill {
        border-radius: 2rem;
    }
    // Rounded
    &.kt-badge--rounded {
        border-radius: $kt-border-radius;
    }
    // Dot style
    &.kt-badge--dot {
        display: inline-block;
        line-height: 6px;
        min-height: 6px;
        min-width: 6px;
        height: 6px;
        width: 6px;
        font-size: 0;
        vertical-align: middle;
        text-align: center;

        &.kt-badge--sm {
            line-height: 4px;
            min-height: 4px;
            min-width: 4px;
            height: 4px;
            width: 4px;
        }

        &.kt-badge--lg {
            line-height: 8px;
            min-height: 8px;
            min-width: 8px;
            height: 8px;
            width: 8px;
        }

        &.kt-badge--xl {
            line-height: 10px;
            min-height: 10px;
            min-width: 10px;
            height: 10px;
            width: 10px;
        }
    }
    // Square mode
    &.kt-badge--square {
        border-radius: 0;
    }
    // Elevated mode
    &.kt-badge--elevate {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    }
    // Custom style
    &.kt-badge--custom {
        display: inline-block;
        line-height: 6px;
        min-height: 6px;
        min-width: 6px;
        height: 6px;
        width: 6px;
        font-size: 0.5rem;
        vertical-align: middle;
        text-align: center;

        &.kt-badge--sm {
            line-height: 4px;
            min-height: 4px;
            min-width: 4px;
            height: 4px;
            width: 4px;
        }

        &.kt-badge--lg {
            line-height: 8px;
            min-height: 8px;
            min-width: 8px;
            height: 8px;
            width: 8px;
        }

        &.kt-badge--xl {
            line-height: 10px;
            min-height: 10px;
            min-width: 10px;
            height: 10px;
            width: 10px;
        }
    }
}
