//
// Aside Brand
//



.kt-aside__brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 0 0 28px;
    height: kt-get($kt-header-config, base, desktop, default, height);

    .kt-aside__brand-logo {
        display: flex;
        justify-content: flex-begin;

        img {
            max-height: 32px;

            .kt-aside--minimize & {
                display: none;
            }
        }

        > span {
            flex: 0 0 35px;
        }

        svg {
            height: 32px;

            .kt-aside--minimize & {
                display: none;
            }
        }

        .material-icons {
            .kt-aside--minimize & {
                display: none!important;
            }
        }
    }

    .kt-aside__brand-tools {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;

        .kt-aside__brand-aside-toggler {
            display: inline-block;
            padding: 0;
            /*background-color: kt-brand-color(base);*/
            border-radius: 50%;
            border: 0;
            transform: scale(0.5);
            transform-origin: right;
            box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);

            > span {
                display: inline-block;

                &:nth-child(2) {
                    display: none;
                }
            }

            svg {
                height: 40px;
                width: 40px;
                /*margin-right: -2px;*/
            }
        }

        .kt-aside__brand-aside-toggler--active {
            transform-origin: left;
        }
    }
}

@include kt-desktop {
	.kt-aside__brand {
		transition: kt-get($kt-aside-config, base, minimize, transition);

		// Header fixed & aside static mode
		.kt-header--fixed.kt-aside--static & {
			position: fixed;
			top: 0;
			left: 0;
			width: kt-get($kt-aside-config, base, default, width);
			z-index: kt-get($kt-header-config, base, desktop, fixed, zindex);
		}

		// Aside minimize mode
		.kt-header--fixed.kt-aside--minimize & {
			justify-content: center;            
            /*padding-right: kt-get($kt-page-padding, desktop);*/

			.kt-aside__brand-tools {
				justify-content: center;
                padding-left: 3px;

				.kt-aside__brand-aside-toggler {
					> span {
						&:nth-child(1) {
							display: none;
						}

						&:nth-child(2) {
							display: inline-block;
						}
					}
				}
			}
		}

		// Aside minimize, header fixed & aside static mode
		.kt-header--fixed.kt-aside--static.kt-aside--minimize & {
			transition: kt-get($kt-aside-config, base, minimize, transition);
			width: kt-get($kt-aside-config, base, minimize, width);
		}
	}
}

@include kt-tablet-and-mobile {
	.kt-aside__brand {
		display: none;
	}
}
