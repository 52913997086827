//
// Forms
//


// Form group
.form-group {
    margin-bottom: 1rem;

    &.form-group-marginless,
    &.form-group-last {
        margin-bottom: 0px;
    }

    label {
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .invalid-feedback,
    .valid-feedback {
        font-weight: 400;
    }

    .form-text {
        font-size: 0.875rem;
    }
    // Space sizing
    &.form-group-md {
        margin-bottom: 1rem;
    }

    &.form-group-sm {
        margin-bottom: 0.5rem;
    }

    &.form-group-xs {
        margin-bottom: 0.25rem;
    }

    &.form-group-last {
        margin-bottom: 0rem;
    }

    .iti {
        display: block;
    }
}

// Form control
.form-control {
    color: kt-base-color(label, 6);
    border: 1px solid kt-base-color(border, 2);
    font-size: 0.875rem;
    height: auto;
    line-height: 1;
    padding: 8px;

    &:focus {
        border: 1px solid kt-base-color(shape, 2);
    }

    &:focus,
    &:active {
        box-shadow: 0 0 0 1px kt-base-color(shape, 2);
    }

    &[readonly] {
        /*background-color: $input-readonly-bg;*/
        color: kt-base-color(label, 6);
        background-color: kt-base-color(background, 9);
    }
    // Pill style
    &.form-control-pill {
        border-radius: 50px;
    }

    &:disabled {
        color: kt-base-color(label, 6);
        background-color: kt-base-color(background, 9);
    }
}

.mat-cell {
    .form-control {
        font-size: 0.8125rem !important;
        line-height: 1rem;
    }
}

.mat-footer-cell {
    .form-control {
        font-size: 0.8125rem !important;
        line-height: 1rem;
    }
}
// Custom inputs
.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(/assets/media/icons/png/baseline_keyboard_arrow_down_black_24dp.png);
    /*background-image: url(/assets/media/icons/png/arrow_down.png);*/
    background-position: right 8px center;
    background-size: auto;
    background-repeat: no-repeat;
    text-overflow: ellipsis;

    &.form-control {
        padding-right: 40px !important;
        line-height: 1.125rem;
    }
}

.custom-file {
	width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
	box-shadow: none !important;
}

.custom-file-label {
	text-align: left;

	&:after {
	    float: left;
	}
}


// Input Group
.input-group {
	@include kt-icons-size((lineawesome: 1.4rem, fontawesome: 1.2rem, flaticon: 1.2rem));

	i {
		color: $input-group-icon-color;
		line-height: 0;
	}

	.form-control.is-valid + .input-group-append,
	.form-control.is-invalid + .input-group-append {
		margin-left: 0;
	}

	.input-group-prepend + .form-control.is-valid,
	.input-group-prepend + .form-control.is-invalid {
		margin-left: 1px;
	}
}

// Validation
.validated {
	.valid-feedback,
	.invalid-feedback {
		display: block;
	}
}

input[type=date].form-control {
    height: 34px;
}

//Custom select
/*select.form-control {
    line-height: 1.125rem!important;
}
select {    
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
}
select + mat-icon {
    float: right;
    margin-top: -30px;
    margin-right: 6px;
    pointer-events: none;
    background-color: #fff;
    padding-right: 6px;
}

select option {
    height: 32px;

    &:hover, &.checked, &:focus > option:checked {
        background: rgba(96, 96, 98, 0.08);
    }
} */
form.row {
    margin-right: -9px;
    margin-left: -9px;
}

.custom-calendar {
    background-image: url(/assets/media/icons/png/baseline_event_black_24dp.png);
    background-position: right 8px center;
    background-size: auto;
    background-repeat: no-repeat;
    text-overflow: ellipsis;

    &.form-control {
        width: 128px;
        padding-right: 40px !important;
        line-height: 1.125rem;
    }
}
