// Buttons
.mat-fab,
.mat-mini-fab,
.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button {
    outline: none !important;
    line-height: 1.4 !important;
    padding: 8px 16px !important;
}

.mat-flat-button {
    border: 1px solid kt-base-color(border, 2) !important;
}

mat-radio-button {
    padding-right: 16px;
}

mat-radio-button {
    .mat-radio-label {
        margin-bottom: 4px;
    }
}

//Maytech
.mat-icon-button {
    &.mat-icon-button--md {
        height: 32px;
        width: 32px;
        border-radius: 3px;
        line-height: 36px !important;
        padding: 0 !important;

        .mat-icon {
            height: 32px;
            width: 32px;
        }

        .material-icons {
            font-size: 24px;
        }
    }

    &.mat-icon-button--sm {
        height: 28px;
        width: 28px;
        line-height: 28px;
        border-radius: 3px;

        .mat-icon {
            height: 28px;
            width: 28px;
        }

        .material-icons {
            font-size: 20px;
        }
    }

    &.mat-icon-button--xsm {
        height: 20px;
        width: 20px;
        line-height: 17px;
        border-radius: 3px;

        .mat-icon {
            height: 20px;
            width: 20px;
        }

        .material-icons {
            font-size: 18px;
        }
    }

    &:hover {
        background-color: rgba(96, 96, 98, 0.08);
    }

    &:active {
        background-color: rgba(1, 124, 56, 0.08);
    }
}

.mat-stroked-button:not([disabled]) {
    border-color: unset;
}

.mat-button.mat-secondary,
.mat-icon-button.mat-secondary,
.mat-stroked-button.mat-secondary {
    border-color: #d3d3d9;
    background-color: #ffffff;
    color: #1F1F1F;
    font-weight: 400;
}

.mt-filter-inside .mat-stroked-button {
    line-height: 1.125rem !important;

    .mat-button-wrapper>* {
        vertical-align: unset;
    }

    &.mat-icon {
        padding: 1px !important;
        min-width: auto;
        height: 36px;
        width: 36px;

        .material-icons {
            vertical-align: middle;
        }
    }

    &.active {
        border: 2px solid #017C38;
        border-radius: 4px;
    }
}
