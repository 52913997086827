
.ml-auto {
	margin-left: auto;
}

.example-full-width {
	width: 100%;
}

.form-group .kt-timeline-3 {
	&::before {
		background-color: transparent;
	}
}


// kt-form
.kt-form {
    .kt-form__filtration,
    .kt-form__group-action {
        margin: 0 0 30px;

        .form-control {
            height: 100%;
            font-size: 0.875rem;
            font-weight: 400;
            padding: 0.5rem 0.75rem;
            color: kt-base-color(label, 6);
            border-color: kt-base-color(border, 2);
            background-color: kt-base-color(background, 5);
        }

        .dropdown-menu {
            .form-control, .form-control:focus {
                border: none;
            }

            .form-control {
                margin-top: 8px;
                padding: 7px 16px;
            }

            .kt-input-icon__icon {
                padding: 0 16px;
            }

            .mat-list-base {
                padding: 0px;
            }

            footer {
                padding: 16px;
                text-align: right;

                .mat-stroked-button:not([disabled]) {
                    border-color: currentColor;
                    line-height: 29px;
                }

                .kt-link {
                    font-size: 0.875rem;
                    color: unset;
                    font-weight: 600;
                    margin-right: 24px;

                    &:hover:after {
                        border-bottom: none;
                        opacity: 0;
                    }
                }
            }
        }

        .kt-input-icon__icon {
            width: unset;
            padding: 0 0.5rem;
            /*z-index: -1;*/
            .material-icons {
                color: kt-base-color(label, 4);
                font-size: 20px;
            }
        }

        ::placeholder {
            color: kt-base-color(label, 4);
        }

        .form-control:focus {
            border: 1px solid kt-base-color(shape, 2);
        }

        select {
            -o-appearance: none;
            -ms-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .mat-icon-button--label {
            color: kt-base-color(label, 6);
            font-size: 0.875rem;
            font-weight: 400;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 8px 4px;
            line-height: 1;
            border-radius: 5px;

            .mat-button-wrapper {
                display: flex;
                align-items: center;
                padding: 8px 4px;
            }

            .material-icons {
                font-size: 20px;
            }

            .mat-icon {
                height: 20px;
                width: 20px;
                line-height: 1;
                margin-right: 4px;
            }

            &.brand {
                color: kt-base-color(shape, 2);
            }

            &.brand:hover {
                color: kt-base-color(shape, 2);
            }

            &:active {
                background-color: kt-base-color(background, 7);
            }
        }

        .mat-list-option {
            height: 32px;
        }

        .mat-list-text {
            font-size: 0.875rem;
            font-weight: 400;
            color: kt-base-color(label, 6);
        }

        .single-select {
            &.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
                padding-left: 0px;
            }
        }

        .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
            padding-left: 8px;
        }
    }

    .kt-form__actions.kt-form__actions--sm {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .kt-form__actions {
        padding: 30px;

        &.kt-form__actions--solid {
            padding: 30px;
            background-color: #f7f8fa;
            border-color: #f7f8fa;
        }
    }
}

.kt-margin {
	&-top-20 {
		margin-top: 20px;
	}
	&-bottom-20 {
		margin-bottom: 20px;
	}
}

.kt-padding-0 {
	padding: 0;
}

.kt-form.kt-form--group-seperator .kt-form__group,
.kt-form.kt-form--group-seperator-dashed .kt-form__group {
	padding-top: 25px;
	padding-bottom: 25px;
}

.mat-input-element.form-control {
    border: 1px solid kt-base-color(border, 2);
}

.mat-error {
    font-size: 0.8125rem;
}

.form-control.ng-dirty.ng-invalid {
    border: 1px solid kt-base-color(border, 3);
    box-shadow: 0 0 0 1px kt-base-color(border, 3);
}

.form-control.mat-list-base.mt-selection-list .mat-list-item {
    height: 24px;
}

.form-control.mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0;
}
