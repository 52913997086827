//
// Global Config
//



// Init global functions and mixins
@import "global/init";

// Layout Breakpoints(bootstrap responsive breakpoints)
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$kt-media-breakpoints: (
// Extra small screen / phone
xs: 0,
// Small screen / phone
sm: 576px,
// Medium screen / tablet
md: 768px,
// Large screen / desktop
lg: 1024px, //1024px,
// Extra large screen / wide desktop
xl: 1399px ) !default;

// Global rounded border mode
$kt-rounded: true !default;

// Border Radius
$kt-border-radius: 5px !default;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: ( close: '\f191', down: '\f110', up: '\f113', left: '\f111', right: '\f112', plus: '\f2c2', minus: '\f28e' ) !default;

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: ( down: '\f1a3', up: '\f1a5', left: '\f1a4', right: '\f19d', close: '\f1b2' ) !default;

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82,63,105,0.05) !default;
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82,63,105,0.1) !default;

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82,63,105, 0.15) !default;

// Custom scrollbar color
//$kt-scroll-color: darken(#ebedf2, 6%) !default;
$kt-scroll-color: #d3d3d9;

// Transition
$kt-transition: all 0.3s !default;

// Modal Z-index
$kt-modal-zindex: 1050 !default;

// dropdown Z-index
$kt-dropdown-zindex: 95 !default;

// State Color
$kt-state-colors: (
// Metronic states
brand: ( base: #017C38, inverse: #ffffff ), light: ( base: #ffffff, inverse: #017C38 ), dark: ( base: #017C38, inverse: #ffffff ), noti: ( base: #ffffff, inverse: #d93024 ),
// Bootstrap states
primary: ( base: #017C38, inverse: #ffffff ), success: ( base: #004e0e, inverse: #ffffff ), info: ( base: #004c7e, inverse: #ffffff ), warning: ( base: #ca5e00, inverse: #ffffff ), danger: ( base: #d93024, inverse: #ffffff ),
// Booking states
draft: ( base: #fff, inverse: #007BFF ), new: ( base: #fff, inverse: #FF5E00 ), onhold: ( base: #fff, inverse: #9C00FF ), confirmed: ( base: #fff, inverse: #00AB4D ), waiting: ( base: #fff, inverse: #009688 ), cancelled: ( base: #fff, inverse: #404040 ),
// Certificate states
unpublish: ( base: #000000, inverse: #BDBDBD ), online: ( base: #000000, inverse: #C8E6C9 )) !default;

// Base colors
$kt-base-colors: ( label: ( 1: #ebebeb, 2: #d3d3d9, 3: #9e9e9e, 4: #606062, 5: #424242, 6: #1f1f20, 7: #000000, 8: #ffffff ), shape: ( 1: #f0f3ff, 2: #017C38, 3: #93a2dd, 4: #646c9a ), grey: ( 1: #f7f8fa, //#f4f5f8
2: #ebedf2, 3: darken(#ebedf2, 3%), 4: darken(#ebedf2, 6%) ), border: ( 1: #e0e0e0, 2: #d3d3d9, 3: #d93024, 4: #017C38 ), background: ( 1: #fcf7f3, 2: #d3d3d9, 3: #9e9e9e, 4: #606062, 5: #ffffff, 6: #e8eee4, 7: #ebf5ef, 8: #017C38, 9: #e9ecef ) ) !default;

// Social network colors(see: https://brandcolors.net/)
$kt-social-colors: ( facebook: ( base: #3b5998, inverse: #ffffff ), google: ( base: #dc4e41, inverse: #ffffff ), twitter: ( base: #4AB3F4, inverse: #ffffff ), instagram: ( base: #517fa4, inverse: #ffffff ), youtube: ( base: #b31217, inverse: #ffffff ), linkedin: ( base: #0077b5, inverse: #ffffff ), skype: ( base: #00aff0, inverse: #ffffff ) ) !default;

// Root Font Settings
$kt-font-families: ( regular: unquote('"Source Sans Pro",Helvetica, sans-serif'), heading: unquote('"Source Sans Pro", Helvetica, sans-serif') ) !default;

// Root Font Settings
$kt-font-size: ( size: ( desktop: 16px, tablet: 16px, mobile: 16px ), weight: 400 ) !default;

// General Link Settings
$kt-font-color: (
/*text: #646c9a,*/
text: #000, link: ( default: kt-state-color(brand), hover: darken(kt-state-color(brand), 6%) ) ) !default;

// Portlet settings
$kt-portlet: ( min-height: ( default: ( desktop: 60px, mobile: 50px ), sm: ( desktop: 50px, mobile: 40px ), lg: ( desktop: 80px, mobile: 60px ), xl: ( desktop: 100px, mobile: 80px ) ), space: ( desktop: 24px, mobile: 15px ), bottom-space: ( desktop: 0px, mobile: 20px ), border-color: kt-base-color(grey, 2), bg-color: #ffffff, shadow: 0px 0px 30px 0px rgba(82,63,105,0.05) ) !default;

// Page padding
$kt-page-padding: ( desktop: 25px, mobile: 15px ) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 8px !default;
// Brand Color
$kt-brand-inverse-color: #ffffff;
$kt-brand-hover-color: #f5f6fc;

// Scrollbar Border Radius
$mt-scrollbar-border-radius: 0px;
