//
// Topbar
//




// Variables
$kt-header-topbar-item-size: 44px;
$kt-header-topbar-large-flag-size: 17px;
$kt-header-topbar-item-svg-size: 24px;
$kt-header-topbar-user-avatar-size: 34px;
$kt-header-topbar-item-size-mobile: 34px;
$kt-header-topbar-item-svg-size-mobile: 30px;
$kt-header-topbar-user-avatar-size-mobile: 30px;

.kt-header__topbar {
    display: flex;
    align-items: stretch;
    padding: 0;
    padding-right: 26px;

    .kt-header__topbar-item {
        display: flex;
        align-items: stretch;
        margin: 0;

        .kt-header__topbar-wrapper {
            cursor: pointer;
            display: flex;
            align-items: stretch;

            .kt-badge.kt-badge--notify {
                position: absolute;
                left: 60%;
                margin-left: -2px;
                top: 15px;
            }
        }

        .material-icons {
            color: #ffffff;
            font-size: 1.5rem !important;
        }

        .kt-header__topbar-icon {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            height: $kt-header-topbar-item-size;
            width: $kt-header-topbar-item-size;
            cursor: pointer;

            @include kt-transition();

            @include kt-rounded {
                border-radius: $kt-border-radius;
            }

            i {
                @include kt-transition();
                font-size: 1.4rem;
                color: #ffffff;
            }

            svg {
                height: $kt-header-topbar-item-svg-size;
                width: $kt-header-topbar-item-svg-size;
            }
        }
        //Feedback
        .kt-header__topbar-feedback {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid #D3D3D9;
            font-size: 0.875rem;
            font-weight: 600;
            padding: 6px 12px !important;
            line-height: normal;
            color: #D93024;
            background-color: #fff;

            .material-icons {
                color: #D93024;
            }
        }
        // User profile
        &.kt-header__topbar-item--user {
            .kt-header__topbar-user {
                padding: 0 8px;
                display: flex;
                align-items: center;
                align-self: center;
                justify-content: center;
                height: $kt-header-topbar-item-size;
                cursor: pointer;

                @include kt-rounded {
                    border-radius: $kt-border-radius;
                }

                .kt-header__topbar-welcome {
                    display: flex;
                    align-self: center;
                    padding: 0 0.55rem 0 0;
                    font-weight: 500;
                    font-size: 0.9rem;
                    color: #636177;
                }

                .kt-header__topbar-username {
                    display: flex;
                    align-self: center;
                    padding: 0 0.55rem 0 0;
                    font-weight: 500;
                    font-size: 1rem;
                    color: #fff;
                }

                img {
                    align-self: center;
                    max-height: $kt-header-topbar-user-avatar-size;

                    @include kt-rounded {
                        border-radius: $kt-border-radius;
                    }
                }

                .kt-badge.kt-badge--username {
                    height: $kt-header-topbar-user-avatar-size;
                    width: $kt-header-topbar-user-avatar-size;
                    border-radius: 50%;
                    text-transform: uppercase;
                    font-size: 1.125rem;
                }
            }
        }
        // Languages
        &.kt-header__topbar-item--langs {
            .kt-header__topbar-icon {
                img {
                    @include kt-rounded {
                        border-radius: $kt-border-radius;
                    }

                    width: $kt-header-topbar-large-flag-size;
                }
            }

            .dropdown-menu {
                .kt-nav__link-icon {
                    padding-right: 10px;

                    img {
                        @include kt-rounded {
                            border-radius: $kt-border-radius;
                        }

                        width: 18px;
                    }
                }
            }
        }

        &:hover,
        &.show {
            .kt-header__topbar-icon {
                @include kt-transition();
                background-color: rgba(#212029, 0.7);

                i {
                    @include kt-transition();
                    color: #fff;
                }
            }

            &.kt-header__topbar-item--user {
                .kt-header__topbar-user {
                    @include kt-transition();
                }
            }
        }
        // Search
        &.kt-header__topbar-item--search {
            display: flex;
            align-items: center;
            // Search
            .kt-quick-search {
                width: 240px;
                padding: 0;
                margin-right: 0.25rem;
                // Form
                .kt-quick-search__form {
                    border-radius: $kt-border-radius;
                    background-color: $kt-brand-hover-color;

                    @include kt-rounded {
                        border-radius: $kt-border-radius;
                    }
                    // Input group
                    .input-group {
                        @include kt-rounded {
                            border-radius: $kt-border-radius;
                        }
                    }
                    // Form control
                    .form-control {
                        font-size: 0.875rem;
                        color: #606062;
                        height: 38px;
                        @include kt-input-placeholder(lighten(#606062, 20%));
                    }

                    i {
                        font-size: 0.875rem;
                        color: #606062;
                    }

                    .input-group-text {
                        padding: 0.25rem 1rem;
                    }
                }
            }
        }
    }
}

@include kt-tablet-and-mobile {
	.kt-header__topbar {
		padding: 0 kt-get($kt-page-padding, mobile);
		background-color: #2b2a35;
		box-shadow: 0px 1px 9px -3px rgba(0,0,0, 0.1);
		transition: all 0.3s ease;
		margin-top: -(kt-get($kt-header-config, topbar, height, mobile));
		height: kt-get($kt-header-config, topbar, height, mobile);
		position: absolute;
		left: 0;
		right: 0;
		justify-content: flex-end;

		// Fixed mobile header
		.kt-header-mobile--fixed & {
			position: fixed;
			z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex) - 1;
		}

		// Topbar shown
		.kt-header__topbar--mobile-on & {
			margin-top: 0;
			transition: all 0.3s ease;
		}

		// Topbar item
		.kt-header__topbar-item {
			margin: 0 2px;
			padding: 0;

			.kt-header__topbar-wrapper {
				display: flex;
				align-items: stretch;

				.kt-badge.kt-badge--notify {
					top: 6px;
				}
			}

			.kt-header__topbar-icon {
				height: $kt-header-topbar-item-size-mobile;
				width: $kt-header-topbar-item-size-mobile;

				i {
					font-size: 1.3rem;
				}	
			}		

			&.kt-header__topbar-item--user {
				margin-left: 2px;
				margin-right: 0;
				padding: 0;

				.kt-header__topbar-user {
					padding: 0 2px;
					height: $kt-header-topbar-item-size-mobile;

					.kt-header__topbar-welcome {
						padding: 0 0.35rem 0 0;
						font-size: 0.9rem;
					}

					.kt-header__topbar-username {
						padding: 0 0.55rem 0 0;
						font-size: 0.9rem;
					}

					img {
						max-height: $kt-header-topbar-user-avatar-size-mobile;
					}

					.kt-badge.kt-badge--username {
						height: $kt-header-topbar-user-avatar-size-mobile;
						width: $kt-header-topbar-user-avatar-size-mobile;
					}
				}
			}		
		}
	}
}
